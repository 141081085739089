import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("path", {
    class: "st0",
    d: "M458.159,404.216c-18.93-33.65-49.934-71.764-100.409-93.431c-28.868,20.196-63.938,32.087-101.745,32.087\r\n\t\tc-37.828,0-72.898-11.89-101.767-32.087c-50.474,21.667-81.479,59.782-100.398,93.431C28.731,448.848,48.417,512,91.842,512\r\n\t\tc43.426,0,164.164,0,164.164,0s120.726,0,164.153,0C463.583,512,483.269,448.848,458.159,404.216z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M256.005,300.641c74.144,0,134.231-60.108,134.231-134.242v-32.158C390.236,60.108,330.149,0,256.005,0\r\n\t\tc-74.155,0-134.252,60.108-134.252,134.242V166.4C121.753,240.533,181.851,300.641,256.005,300.641z"
  })], -1)]));
}