import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("path", {
    class: "st0",
    d: "M355.022,115.897c22.422,0,40.603-18.19,40.603-40.616c0-22.427-18.181-40.608-40.603-40.608\r\n\t\tc-22.434,0-40.616,18.182-40.616,40.608C314.406,97.707,332.588,115.897,355.022,115.897z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M509.402,232.445l-26.925-40.501c-6.543-9.57-15.22-17.486-25.33-23.155l-52.81-29.574\r\n\t\tc-9.676-4.524-16.996-7.402-26.189-6.159l-25.268,3.436c-9.422,1.275-18.01,6.077-24.037,13.429l-48.096,51.183l-44.055,16.244\r\n\t\tc-8.346,3.075-12.825,12.137-10.186,20.627l0.204,0.654c2.593,8.358,11.196,13.29,19.715,11.327l35.901-8.284\r\n\t\tc8.952-2.07,17.458-5.758,25.072-10.894l24.97-16.783l8.816,67.033c0.647,4.916-0.343,9.913-2.822,14.214l-74.758,129.848\r\n\t\tc-5.333,9.242-2.176,21.068,7.05,26.45l0.642,0.376c8.735,5.087,19.908,2.633,25.69-5.643l83.558-119.534l25.322,50.537\r\n\t\tc1.873,3.738,4.351,7.132,7.336,10.044l56.164,54.92c7.091,6.928,18.305,7.23,25.747,0.712l0.9-0.786\r\n\t\tc3.819-3.345,6.134-8.073,6.436-13.135c0.302-5.055-1.431-10.036-4.826-13.806l-47.421-52.818L424.44,269.16l-0.09,0.082\r\n\t\tl-7.394-72.947l26.115,11.753l41.752,43.945c5.251,5.537,13.757,6.429,20.038,2.094l0.426-0.295\r\n\t\tC512.256,248.999,514.096,239.495,509.402,232.445z"
  }), _createElementVNode("polygon", {
    class: "st0",
    points: "129.123,101.06 43.287,42.435 262.74,42.435 262.74,8.934 0,8.934 0,444.44 43.287,444.44 \r\n\t\t129.123,503.066 129.123,486.315 129.123,444.44 233.8,444.44 233.8,410.94 129.123,410.94 \t"
  })], -1)]));
}