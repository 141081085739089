import axios from "axios";

// 输出当前时间
const printNow = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
};

// debug
export const consoleDebug = (funcName, msg) => {
  if (axios.justle != "production") {
    console.log(printNow(), `| ${funcName}:\n`, msg);
  }
};

// 处理
export const checkExpire = (err, router) => {
  if (err.response && err.response.status && err.response.status == 401) {
    alert("Login expired, please login again!");
    localStorage.removeItem("token");
    router.push("/benefit/login");
  }
};

// 防抖
export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
