import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  id: "app",
  class: "app-inner"
};
const _hoisted_2 = {
  id: "navigator",
  class: "navigator"
};
const _hoisted_3 = {
  id: "app-body",
  class: "app-body"
};
const _hoisted_4 = {
  key: 0,
  id: "app-foot"
};
const _hoisted_5 = {
  id: "bottom-button",
  class: "bottom-button"
};
const _hoisted_6 = {
  key: 0,
  id: "cookies-confirm",
  class: "cookies-confirm"
};
import { onMounted } from "vue";
import { Crisp } from "crisp-sdk-web";
import { getCartNum } from "./components/common/func_cart.js";
import { useRouter } from "vue-router";
import navigator from "./components/common/common_nav.vue";
import foot from "./components/common/common_foot.vue";

// 引入icon
import iconArrowUp from "./components/common/icon_arrow_up.vue";
// import iconHome from "./components/common/icon_home.vue";
import iconBalloon from "./components/common/icon_balloon.vue";

// 判断当前url，用于显示是显示iconHome还是iconBalloon
import { ref } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      Crisp.configure("9bc85d1c-ef91-4a7f-a997-050eb1abcdee");
    });
    const scrollToTop = () => {
      const appBody = document.getElementById("app-body");
      // 模拟滚动到顶部
      if (appBody) {
        appBody.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    };

    // 在内存中获得购物车中的商品数量
    const cartNum = ref(0);
    const countCart = () => {
      cartNum.value = getCartNum();
    };
    countCart();
    const router = useRouter();
    const backToHome = () => {
      if (isShowHome.value) {
        router.push("/");
      } else {
        window.$crisp.push(["do", "chat:open"]);
      }
    };
    const isShowHome = ref(true);
    // 每次改变url时，判断url是否是purchase及其子页面，如果是则不显示iconHome
    router.afterEach(to => {
      if (to.path.includes("encouragement") || to.path.includes("encourangement") || to.path.includes("cart")) {
        isShowHome.value = false;
      } else {
        isShowHome.value = true;
      }
    });

    // 让bottom-button在app-body距离顶部100px时显示，越往下透明度越高
    onMounted(() => {
      const appBody = document.getElementById("app-body");
      if (appBody) {
        appBody.addEventListener("scroll", () => {
          const bottomButton = document.getElementById("bottom-button");
          if (bottomButton) {
            // 获取滚动的距离
            const scrollTop = appBody.scrollTop;
            // 获取整个页面的高度 + 滚动的高度
            const scrollHeight = appBody.scrollHeight - appBody.clientHeight;
            const opacity = Math.max(scrollTop / scrollHeight, 0.2);
            bottomButton.style.opacity = opacity;
          }
        });
      }
    });

    // 判断footer是否需要显示。如果页面的url来自于purchase及其子页面，则不显示footer
    const isShowFooter = ref(true);
    const currentPath = router.currentRoute.value.path;
    // 如果url中有purchase，则不显示footer
    if (currentPath.includes("purchase")) {
      isShowFooter.value = false;
    }

    // 判断是否显示cookies-confirm
    const isShowCookies = ref(false);
    // 如果localStorage中没有isConfirmCookies，则显示cookies-confirm
    if (!localStorage.getItem("isConfirmCookies")) {
      isShowCookies.value = true;
    }
    const confirmCookies = () => {
      isShowCookies.value = false;
      localStorage.setItem("isConfirmCookies", true);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(navigator, {
        cartNum: cartNum.value
      }, null, 8, ["cartNum"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view, {
        onUpdateCartNum: countCart
      }), isShowFooter.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(foot)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        id: "back-to-home",
        class: "imgbtn",
        onClick: backToHome
      }, [_createVNode(iconBalloon)]), _createElementVNode("div", {
        id: "back-to-top",
        class: "imgbtn",
        onClick: scrollToTop
      }, [_createVNode(iconArrowUp)])]), _createVNode(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(() => [isShowCookies.value ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[0] || (_cache[0] = _createElementVNode("div", {
          id: "cookies-confirm-text"
        }, " We use cookies to ensure you get the best experience on our website. By continuing to browse the site, you agree to our use of cookies. ", -1)), _createElementVNode("div", {
          id: "cookies-confirm-btn",
          onClick: confirmCookies
        }, "OK")])) : _createCommentVNode("", true)]),
        _: 1
      })]);
    };
  }
};