import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("path", {
    class: "st0",
    d: "M487.592,8.508V8.492H383.307l-21.748,82.682H0l51.516,196.211h0.348h257.998l-10.002,37.986H61.838\r\n\t\tl12.879,49.052H337.73l83.387-316.877h65.98c0.121,0.002,0.234,0.035,0.358,0.035c13.554,0,24.545-10.99,24.545-24.545\r\n\t\tC512,19.529,501.082,8.582,487.592,8.508z M89.359,238.332l-25.808-98.108h285.07l-25.811,98.108H89.359z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M107.363,400.649c-28.355,0-51.426,23.07-51.426,51.43c0,28.36,23.07,51.43,51.426,51.43\r\n\t\tc28.328,0,51.375-23.07,51.375-51.43C158.738,423.719,135.692,400.649,107.363,400.649z"
  }), _createElementVNode("path", {
    class: "st0",
    d: "M295.496,400.649c-28.359,0-51.43,23.07-51.43,51.43c0,28.36,23.07,51.43,51.43,51.43\r\n\t\tc28.328,0,51.375-23.07,51.375-51.43C346.871,423.719,323.824,400.649,295.496,400.649z"
  })], -1)]));
}