import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("path", {
    class: "st0",
    d: "M257.135,19.179C103.966,19.179,0,97.273,0,218.763c0,74.744,31.074,134.641,91.108,173.176\r\n\t\tc4.003,2.572,8.728,2.962,6.954,10.365c-7.159,29.935-19.608,83.276-19.608,83.276c-0.526,2.26,0.321,4.617,2.162,6.03\r\n\t\tc1.841,1.402,4.335,1.607,6.381,0.507c0,0,87.864-52.066,99.583-58.573c27.333-15.625,50.877-18.654,68.557-18.654\r\n\t\tC376.618,414.89,512,366.282,512,217.458C512,102.036,418.973,19.179,257.135,19.179z M136.911,258.117\r\n\t\tc-16.862,0-30.529-13.666-30.529-30.528c0-16.862,13.666-30.539,30.529-30.539c16.872,0,30.538,13.677,30.538,30.539\r\n\t\tC167.449,244.451,153.782,258.117,136.911,258.117z M255.994,258.117c-16.861,0-30.528-13.666-30.528-30.528\r\n\t\tc0-16.862,13.666-30.539,30.528-30.539c16.873,0,30.539,13.677,30.539,30.539C286.534,244.451,272.867,258.117,255.994,258.117zM375.089,258.117c-16.862,0-30.538-13.666-30.538-30.528c0-16.862,13.676-30.539,30.538-30.539\r\n\t\tc16.872,0,30.538,13.677,30.538,30.539C405.627,244.451,391.961,258.117,375.089,258.117z"
  })], -1)]));
}