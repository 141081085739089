import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  version: "1.1",
  id: "_x32_",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {
    "enable-background": "new 0 0 512 512"
  },
  "xml:space": "preserve"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("g", null, [_createElementVNode("polygon", {
    class: "st0",
    points: "237.61,331.547 271.554,365.5 347.11,289.946 381.054,256 347.11,222.054 271.554,146.5 \r\n\t\t237.61,180.453 289.164,232 0,232 0,280 289.164,280 \t"
  }), _createElementVNode("polygon", {
    class: "st0",
    points: "464,0 416,0 192,0 144,0 96,0 96,48 96,80 96,160 144,160 160,160 160,64 448,64 448,448 160,448 \r\n\t\t160,352 144,352 96,352 96,432 96,464 96,512 144,512 192,512 416,512 464,512 512,512 512,464 512,416 512,96 512,48 512,0 \t"
  })], -1)]));
}